<template>
  <div class="container honor" v-loading="loading">
    <div class="top">
      <div class="name">{{ details.name }}</div>

      <div class="tag_style cursorP" @click="toCompanyDetail">查看企业详情</div>
    </div>

    <div class="table">
      <div class="table_cell">
        <div class="label">评价名称</div>
        <div class="value">
          {{ details.name | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">评价详细</div>
        <div class="value">
          <pre>{{ details.content | noVoid }}</pre>
        </div>
      </div>

      <div class="table_cell">
        <div class="label">公司名称</div>
        <div class="value link" @click="toCompanyDetail">
          {{ details.company | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">给予单位</div>
        <div class="value">
          {{ details.announcedUnits | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">相关人员</div>
        <div class="value">
          {{ details.staff | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">评价时间</div>
        <div class="value">
          {{ details.time | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">评价等级</div>
        <div class="value">
          {{ details.grade | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">信息来源</div>
        <div class="value">
          <div v-if="details.informationSource.length > 0">
            <span
              v-for="item in details.informationSource"
              class="vip"
              @click="linkToUrl(item.key)"
            >
              {{ item.value }}
            </span>
          </div>
          <span v-else>--</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* 评价详情 */
import { searchEvaluate } from "@/api/itemizedQuery";

export default {
  data() {
    return {
      id: "",
      details: { informationSource: [] },
      loading: false,
    };
  },

  methods: {
    /* 获取数据 */
    async getData() {
      this.loading = true;
      const params = {
        id: this.id,
        pageNum: 1,
        pageSize: 10,
      };

      const { data } = await searchEvaluate(params);
      const { records } = data;
      if (records.length > 0) {
        const details = records[0];
        this.details = details;
      }
      this.loading = false;
    },

    /* 操作 */
    // 跳转到网页
    linkToUrl(url) {
      if (url) {
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.target = "_blank";
        document.body.appendChild(a);
        a.click();
      } else {
        this.$message.warning("暂无跳转地址");
      }
    },
    // 跳转到企业详情页面
    toCompanyDetail() {
      let id = this.details.companyId;
      if (id) {
        this.$router.push({
          name: "companyDetails",
          params: { name: "评价信息" },
          query: {
            id,
          },
        });
      } else {
        this.$message.warning("暂无企业信息！");
      }
    },

    /* 初始化 */
    __init__() {
      this.id = this.$route.params.id;
      this.getData();
    },
  },

  created() {
    this.__init__();
  },

  activated() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
.honor {
  padding: 20px;
  background-color: #fff;

  .top {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;

    .name {
      font-size: 20px;
      font-weight: bold;
      color: #333;
      margin-right: 20px;
      flex: 1;
    }
  }

  pre {
    margin: 0px !important;
    font-family: "BlinkMacSystemFont";
    font-size: 14px;
  }

  .table {
    .table_cell {
      display: flex;
      align-items: stretch;
      border: 1px solid #ecf1ff;
      border-bottom: none;
      border-right: none;
      font-size: 14px;

      &:last-child {
        border-bottom: 1px solid #ecf1ff;
      }

      .label {
        padding: 15px;
        min-width: 200px;
        text-align: center;
        color: #666;
        display: flex;
        align-items: center;
        background-color: #f5faff;
      }

      .value {
        display: flex;
        align-items: center;
        padding: 15px;
        flex: 1;
        color: #666;
        border-right: 1px solid #ecf1ff;
        border-left: 1px solid #ecf1ff;
        line-height: 1.5;
      }
    }
  }
}
</style>
